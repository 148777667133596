@font-face {
    font-family: "db_heaventbold";
    src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.svg#db_heaventbold")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "db_heaventmed";
    src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.svg#db_heaventmed")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "db_heaventthin";
    src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.svg#db_heaventthin")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  